<template>
  <div style="overflow: auto;">
    <div class="layout">
      <div class="header"
           :style="{ background: `url(${require('@/assets/image/zcybakh/bg.jpg')}) no-repeat center/cover`, height: '55px' }">
        <div class="wrap">
          <h1>济南仲裁委员会仲裁员办案过程监督</h1>
        </div>
      </div>
      <div class="hpage">
        <div class="wrap">
          <div class="pic">
            <img src="@/assets/image/zcybakh/uico1.jpg" alt="">
          </div>
          <div class="box">
            <ul class="clearfix">
              <li v-if="PreTrialCases">
                <div class="hs flanx">
                  <h3>庭前准备</h3>
                  <h4>超期预警：{{ totalCases }}件</h4>
                </div>
                <div class="bs">
                  <div class="flanx">
                    <h3></h3>
                    <dl>
                      <dd>签署公正声明</dd>
                      <dd>庭前合议</dd>
                    </dl>
                  </div>
                  <div class="span" v-if="PreTrialCases && PreTrialCases.qsgzsmMap && PreTrialCases.tqhyMap">
                    <i :style="{ width: qsgzsmWidth, background: '#22b14c' }">
                      <span>{{ PreTrialCases.qsgzsmMap.length }}件</span>
                    </i>
                    <i :style="{ width: tqhyWidth, background: '#fff000' }">
                      <span>{{ PreTrialCases.tqhyMap.length }}件</span>
                    </i>
                  </div>
                </div>
                <div class="fs flanx">
                  <h3>签署公正声明</h3>
                  <h4>{{ PreTrialCases.qsgzsmMap.length }}件</h4>
                  <h3>庭前合议</h3>
                  <h4>{{ PreTrialCases.tqhyMap.length }}件</h4>
                </div>
                <a href="#" class="btn" @click="showDetails(1)">查看详情</a>
              </li>
              <li>
                <div class="hs flanx">
                  <h3>开庭情况</h3>
                  <h4>超期预警：100件</h4>
                </div>
                <div class="bs">
                  <div class="flanx">
                    <h3></h3>
                    <dl>
                      <dd>首次开庭</dd>
                      <dd>多次开庭</dd>
                      <dd>庭后合议</dd>
                    </dl>
                  </div>
                  <div class="span">
                    <i style="width: 30%;background: #22b14c;"><span>20件</span></i>
                    <i style="width: 20%;background: #fff000;"><span>30件</span></i>
                    <i style="width: 50%;background: #ed1c24;"><span>50件</span></i>
                  </div>
                </div>
                <div class="fs flanx">
                  <h3>首次开庭</h3>
                  <h4>20件</h4>
                  <h3>多次开庭</h3>
                  <h4>30件</h4>
                  <h3>庭后合议</h3>
                  <h4>50件</h4>
                </div>
                <a href="#" class="btn" @click="showDetails(2)">查看详情</a>
              </li>
              <li>
                <div class="hs flanx">
                  <h3>裁决、管辖异议决定</h3>
                  <h4>超期预警：150件</h4>
                </div>
                <div class="bs">
                  <div class="flanx">
                    <h3></h3>
                    <dl>
                      <dd>起草裁决</dd>
                      <dd>文书会签</dd>
                      <dd>文书确认</dd>
                    </dl>
                  </div>
                  <div class="span">
                    <i style="width: 30%;background: #22b14c;"><span>20件</span></i>
                    <i style="width: 20%;background: #fff000;"><span>30件</span></i>
                    <i style="width: 50%;background: #ed1c24;"><span>50件</span></i>
                  </div>
                </div>
                <div class="fs flanx">
                  <h3>起草裁决</h3>
                  <h4>30件</h4>
                  <h3>文书会签</h3>
                  <h4>45件</h4>
                  <h3>文书确认</h3>
                  <h4>75件</h4>
                </div>
                <a href="#" class="btn" @click="showDetails(3)">查看详情</a>
              </li>
              <li>
                <div class="hs flanx">
                  <h3>调解、撤回决定书</h3>
                  <h4>超期预警：78件</h4>
                </div>
                <div class="bs">
                  <div class="flanx">
                    <h3></h3>
                    <dl>
                      <dd>文书会签</dd>
                      <dd>文书确认</dd>
                    </dl>
                  </div>
                  <div class="span">
                    <i style="width: 40%;background: #22b14c;"><span>40件</span></i>
                    <i style="width: 60%;background: #fff000;"><span>60件</span></i>
                  </div>
                </div>
                <div class="fs flanx">
                  <h3>文书会签</h3>
                  <h4>31件</h4>
                  <h3>文书确认</h3>
                  <h4>47件</h4>
                </div>
                <a href="#" class="btn" @click="showDetails(4)">查看详情</a>
              </li>
              <li v-if="Data">
                <div class="hs flanx">
                  <h3>庭前准备监管项</h3>
                </div>
                <div class="dl">
                  <div class="dd">
                    <h3>签署公正声明时间-组庭审批时间超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('qsgzsm') }}</span>
                      日</h3>
                  </div>
                  <div class="dd">
                    <h3>庭前合议时间-签署公正声明时间超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('tqhy') }}</span>
                      日</h3>
                  </div>
                  <div class="dd">
                    <h3>组庭审批时间：</h3><h4>--分管主任审批通过时间</h4>
                  </div>
                  <div class="dd">

                    <h3>签署公正声明时间：</h3><h4>--最后一个仲裁员签署时间</h4>
                  </div>
                  <div class="dd">
                    <h3>庭前合议时间：</h3><h4>--秘书上传合议资料提交时间</h4>
                  </div>
                </div>
              </li>
              <li v-if="Data">
                <div class="hs flanx">
                  <h3>开庭监管项</h3>
                </div>
                <div class="dl_l">
                  <div class="dd">
                    <h3>首次开庭时间-签署公正声明时间超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('sckt') }}</span>
                      日</h3>
                  </div>
                  <div class="dd">
                    <h3>最后开庭时间-首次开庭时间超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('zhkt') }}</span>
                      日</h3>
                  </div>
                  <div class="dd">
                    <h3>庭后合议时间-最后开庭时间超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('thhy') }}</span>
                      日</h3>
                  </div>
                  <div class="dd">
                    <h3>首次开庭时间：</h3><h4>--第一次开庭结果为“正常开庭”的开庭时间</h4>
                  </div>
                  <div class="dd">
                    <h3>最后开庭时间：</h3><h4>--最后一次开庭结果为“正常开庭”的开庭时间</h4>
                  </div>
                  <div class="dd">
                    <h3>庭后合议时间：</h3><h4>--秘书上传合议资料提交时间</h4>
                  </div>

                </div>
              </li>
              <li v-if="Data">
                <div class="hs flanx">
                  <h3>裁决、管辖异议决定监管</h3>
                </div>
                <div class="dl">
                  <div class="dd">
                    <h3>起草裁决时间-庭后合议时间不得超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('qccj') }}</span>
                      天</h3>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭会签时间-秘书核稿时间不得超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('cj_zcthq') }}</span>
                      天</h3>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭确认时间-秘书确认时间不得超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('cj_zctqr') }}</span>
                      天</h3>
                  </div>
                  <div class="dd">
                    <h3>起草裁决时间：</h3><h4>--仲裁员上传裁决书或决定书给秘书核稿的时间</h4>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭会签时间：</h3><h4>--三人庭以最后会签时间为仲裁庭会签时间</h4>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭确认时间：</h3><h4>--三人庭以最后确认时间为仲裁庭确认时间</h4>
                  </div>
                </div>
              </li>
              <li v-if="Data">
                <div class="hs flanx">
                  <h3>调解、撤回决定书</h3>
                </div>
                <div class="dl">
                  <div class="dd">
                    <h3>仲裁庭会签时间-秘书核稿时间不得超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('tj_zcthq') }}</span>
                      天</h3>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭确认时间-秘书确认时间不得超
                      <span style="font-weight: bold;color:#ff0000;">{{ getDayByType('tj_zctqr') }}</span>
                      天</h3>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭会签时间：</h3><h4>--三人庭以最后会签时间为仲裁庭会签时间</h4>
                  </div>
                  <div class="dd">
                    <h3>仲裁庭确认时间：</h3><h4>--三人庭以最后确认时间为仲裁庭确认时间</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer" style="position: relative; clear: both;">
        济南仲裁委员会办公室 版权所有©
      </div>
    </div>

    <div class="mask" v-if="showMask">
    </div>
    <div class="umktanc" v-if="showDetailsPopup">
      <div class="icons" @click="hideDetailsPopup">
        <img src="@/assets/image/zcybakh/q5.png" alt="">
      </div>
      <div class="utable">
        <table border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th>案号</th>
            <th>仲裁庭</th>
            <th>申请人</th>
            <th>被申请人</th>
            <th>组庭日期</th>
            <th>案由</th>
            <th>监管项</th>
          </tr>
          <tr v-for="caseItem in table" :key="caseItem.id">
            <td>{{ caseItem.ah }}</td>
            <td>{{ caseItem.zct }}</td>
            <td>{{ caseItem.sqr }}</td>
            <td>{{ caseItem.bsqr }}</td>
            <td>{{ formatDate(caseItem.ztrq) }}</td>
            <td>{{ caseItem.ay }}</td>
            <td>{{ caseItem.jgx }}</td>
          </tr>
        </table>
        <div class="pages">
          <ul>
            <li>
              <a href="#" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">上一页</a>
            </li>

            <!-- 第一页 -->
            <li v-if="startPage > 1">
              <a href="#" @click="changePage(1)">1</a>
            </li>

            <!-- 省略号，表示中间页码省略 -->
            <li v-if="startPage > 2">...</li>

            <!-- 当前页附近的页码 -->
            <li v-for="page in pages" :key="page" :class="{ on: page === currentPage }">
              <a href="#" @click="changePage(page)">{{ page }}</a>
            </li>

            <!-- 省略号，表示中间页码省略 -->
            <li v-if="endPage < totalPages - 1">...</li>

            <!-- 最后一页 -->
            <li v-if="endPage < totalPages">
              <a href="#" @click="changePage(totalPages)">{{ totalPages }}</a>
            </li>

            <li>
              <a href="#" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">下一页</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      Data: [],
      showMask: false,
      showDetailsPopup: false,
      PreTrialCases: {//庭前准备
        qsgzsmMap: [],
        tqhyMap: []
      },
      cases: [
        {
          id: 1001,
          caseNumber: "2024-00001",
          arbitrationTribunal: "黄娟、张山、王丽丽",
          applicant: "中建八局",
          respondent: "山东省路桥集团有限公司",
          groupingDate: "2024-01-10",
          caseReason: "建筑合同纠纷",
          supervisionItem: "张山公正声明未签署"
        },
        {
          id: 1002,
          caseNumber: "2024-00001",
          arbitrationTribunal: "黄娟、张山、王丽丽",
          applicant: "中建八局",
          respondent: "山东省路桥集团有限公司",
          groupingDate: "2024-01-10",
          caseReason: "建筑合同纠纷",
          supervisionItem: "超 3 天未进行庭前合议"
        },
        {
          id: 1003,
          caseNumber: "2024-00001",
          arbitrationTribunal: "黄娟、张山、王丽丽",
          applicant: "中建八局",
          respondent: "山东省路桥集团有限公司",
          groupingDate: "2024-01-10",
          caseReason: "建筑合同纠纷",
          supervisionItem: "超 30 天首次开庭（首次开庭日期 2024-05-10）"
        },
        {
          id: 1004,
          caseNumber: "2024-00001",
          arbitrationTribunal: "黄娟、张山、王丽丽",
          applicant: "中建八局",
          respondent: "山东省路桥集团有限公司",
          groupingDate: "2024-01-10",
          caseReason: "建筑合同纠纷",
          supervisionItem: "最后一次开庭日期 2024-10-10（距首次开庭 2024-08-10 超 20 天）"
        }
      ],
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      pageRange: 5,      // 显示的页码范围
      table: [],
      bananinfoIds: [],
    };
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        // 调用 fetchBananInfoData 并传入当前页数和 pageSize
        this.fetchBananInfoData(this.bananinfoIds, this.currentPage, this.pageSize);
      }
    },
    initQuery() {
      this.axios.get(`/pingZhan/set/zcyBakhSet?pageNum=1&pageSize=10`)
          .then(response => {
            console.log(response);
            this.Data = response.data.list;
            this.fetchPreTrialCases();
          })
          .catch(error => {
            console.error(error);
          });
    },
    // 获取庭前准备的所有案件数
    fetchPreTrialCases() {
      // 获取两个参数
      const qsgzsmDays = this.getDayByType('qsgzsm');
      const tqhyDays = this.getDayByType('tqhy');
      this.axios.get(`/pingZhan/zcylog/calculateLogs`, {
        params: {
          signStatementTime: qsgzsmDays,
          preTrialDeliberationTime: tqhyDays
        }
      }).then(response => {
        console.log(response);
        this.PreTrialCases = response.data;
      }).catch(error => {
        console.error(error);
      });
    },
    showDetails(num) {
      this.showDetailsPopup = true;
      if (num == 1) {
        //庭前准备
        // 提取 PreTrialCases 中的所有 baaninfo_id
        const bananinfoIds = [
          ...this.PreTrialCases.qsgzsmMap.map(item => item.bananinfo_id),
          ...this.PreTrialCases.tqhyMap.map(item => item.bananinfo_id)
        ];
        this.bananinfoIds = bananinfoIds
        // 发送请求到后端
        this.fetchBananInfoData(bananinfoIds);
      }
    },
    fetchBananInfoData(bananinfoIds, currentPage = 1, pageSize = 10) {
      // 计算当前页要发送的ID范围
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const paginatedIds = bananinfoIds.slice(startIndex, endIndex);

      this.axios.post('/pingZhan/zcylog/getBananInfo',
          paginatedIds  // 只发送当前页的ID子集
      )
          .then(response => {
            // 处理后端返回的数据
            console.log(response);
            // 更新表格数据和分页信息
            this.table = response.data.data.cases;  // 当前页数据
            this.totalPages = bananinfoIds.length;  // 总页数
          })
          .catch(error => {
            console.error('获取 bananinfo 数据失败:', error);
          });
    },
    hideDetailsPopup() {
      this.showDetailsPopup = false;
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false  // 使用24小时制
      });
    }
  },
  mounted() {
    this.initQuery();

  },
  computed: {
    getDayByType() {
      return (type) => {
        const item = this.Data.find(entry => entry.type === type);
        return item ? item.day : '0';
      };
    },
    totalCases() {
      // 计算总案件数
      if (this.PreTrialCases && Array.isArray(this.PreTrialCases.qsgzsmMap) && Array.isArray(this.PreTrialCases.tqhyMap)) {
        return this.PreTrialCases.qsgzsmMap.length + this.PreTrialCases.tqhyMap.length;
      }
      return 0;
    },
    qsgzsmWidth() {
      // 计算 qsgzsm 的宽度
      const total = this.totalCases;
      return total ? `${(this.PreTrialCases.qsgzsmMap.length / total * 100).toFixed(2)}%` : '0%';
    },
    tqhyWidth() {
      // 计算 tqhy 的宽度
      const total = this.totalCases;
      return total ? `${(this.PreTrialCases.tqhyMap.length / total * 100).toFixed(2)}%` : '0%';
    },
    // 计算 startPage 和 endPage
    startPage() {
      return Math.max(1, this.currentPage - Math.floor(this.pageRange / 2));
    },
    endPage() {
      return Math.min(this.totalPages, this.currentPage + Math.floor(this.pageRange / 2));
    },
    pages() {
      const pages = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  }
};
</script>

<style scoped>
@import "@/assets/css/zcybakh/slicy.css";
@import "@/assets/css/zcybakh/swiper.min.css";
@import "@/assets/css/zcybakh/style.css";

</style>