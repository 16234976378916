import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue';
import Home from '../views/home.vue';
import Sing from '../views/sing.vue';
import zcyRoutes from './zcy/zcyRoutes';
import NProgress from "nprogress";
import zcy from '@/views/zcy/zcy.vue'
import handling from "@/views/case/handling.vue";
import handling_children from "@/router/case/handling_children";
import zcybakh from "@/views/zcybakh/index.vue";
import set from "@/views/system/set.vue"


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login' //重定向
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/sing',
        name: 'Sing',
        component: Sing
    },
    {//仲裁员
        path: '/zcy/:id',
        name: 'Zcy',
        component: zcy,
        redirect: {name: 'zcy_details'},
        children: zcyRoutes,
    },
    {//案件详情
        path: '/handling/:id',
        name: 'handling',
        component: handling,
        redirect: {name: 'handling_details'},
        children: handling_children
    },
    {//仲裁员办案考核
        path: '/zcybakh',
        name: 'zcybakh',
        component: zcybakh,
    },
    {//设置仲裁员办案考核
        path: '/set',
        name: 'set',
        component: set,
    },

]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router
