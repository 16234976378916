<template>
  <div class="container">
    <!--  搜索  -->
    <el-form :inline="true" :model="searchForm" class="search-form" v-if="showSearch" style="margin-top: 20px;">
      <el-form-item>
        <template #label>
          <span class="custom-label">字典名称</span>
        </template>
        <el-input placeholder="请输入字典名称" v-model="searchForm.remark" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search" size="small">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <!--工具栏-->
    <el-row class="button-row" style="margin-top: 10px;">
      <el-button type="primary" size="mini" icon="el-icon-plus" @click="showAddDialog">新增</el-button>
      <!--      <el-button type="ssuccess" size="mini" icon="el-icon-edit" @click="handleEdit" :disabled="!isRowSelected">修改
            </el-button>-->
      <!-- 批量删除确认窗口（使用 Popconfirm 气泡确认框） -->
      <el-popconfirm
          style="margin-left: 10px"
          title="确定要批量删除选中的项吗？"
          @confirm="confirmBatchDelete"
          @cancel="batchDeleteDialogVisible = false">
        <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete" :disabled="!isRowSelected">
          批量删除
        </el-button>
      </el-popconfirm>
      <!--      <el-button type="warning" size="mini" icon="el-icon-download" @click="handleExport">导出</el-button>-->
      <el-button style="margin-left: 10px" type="danger" size="mini" plain icon="el-icon-refresh"
                 @click="handleRefresh">刷新缓存
      </el-button>
      <el-button type="info" size="mini" icon="el-icon-search" @click="toggleSearch" style="float: right;">隐藏搜索
      </el-button>
    </el-row>
    <!--  表格  -->
    <el-table :data="tableData" style="width: 100%; margin-top: 20px;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="字典编码" width="200"></el-table-column>
      <el-table-column prop="remark" label="字典名称" width="450">
        <template slot-scope="scope">
          <el-tag>{{ scope.row.remark }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="day" label="天数" width="250">
        <template slot-scope="scope">
          <el-tag type="danger" style="font-weight: bold">{{ scope.row.day }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间" width="300">
        <template slot-scope="scope">
          <el-tag type="info">{{ scope.row.createtime }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">修改</el-button>
          <el-popconfirm
              style="margin-left: 10px"
              title="确定要删除选中的项吗？"
              @confirm="handleDelete(scope.row)">
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!--   分页 -->
    <div class="pagination-container">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!-- 新增窗口 -->
    <el-dialog title="新增字典" :visible.sync="addDialogVisible">
      <el-form ref="addForm" :model="addForm" label-width="80px">
        <el-form-item label="字典名称">
          <el-input v-model="addForm.remark"/>
        </el-form-item>
        <el-form-item label="天数">
          <el-input v-model="addForm.day"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAdd">确定</el-button>
          <el-button @click="addDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑窗口 -->
    <el-dialog title="编辑字典" :visible.sync="editDialogVisible">
      <el-form ref="editForm" :model="editForm" label-width="80px">
        <el-form-item label="字典名称">
          <el-input v-model="editForm.remark"/>
        </el-form-item>
        <el-form-item label="天数">
          <el-input v-model="editForm.day"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEdit">确定</el-button>
          <el-button @click="editDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchForm: {
        remark: '',
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      showSearch: true,
      selectedRows: [],
      isRowSelected: false,
      addDialogVisible: false,
      addForm: {
        remark: '',
        day: '',
      },
      editDialogVisible: false,
      editForm: {},
      batchDeleteDialogVisible: false // 新增批量删除确认窗口的显示状态
    };
  },
  methods: {
    initQuery() {
      this.axios.get(`/pingZhan/set/zcyBakhSet?pageNum=${this.currentPage}&pageSize=${this.pageSize}`)
          .then(response => {
            console.log(response);
            this.tableData = response.data.list;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error(error);
          });
    },
    search() {
      // 实现搜索逻辑
      axios.get(`/pingZhan/set/searchByRemark?remark=${this.searchForm.remark}`)
          .then(response => {
            console.log(response);
            this.tableData = response.data;
          })
          .catch(error => {
            console.error(error);
          });
    },
    reset() {
      this.searchForm = {
        remark: '',
      };
      // 重新获取数据
      this.initQuery()
    },
    handleAdd() {
      this.addDialogVisible = true;
    },
    handleEdit(row) {
      this.editDialogVisible = true;
      this.editForm = {...row};
    },
    handleDelete(row) {
      // 发送删除请求
      axios.delete(`/pingZhan/set/${row.id}`)
          .then(response => {
            console.log(response);
            this.$message({
              message: '删除成功！',
              type: 'success'
            });
            this.initQuery();
          })
          .catch(error => {
            console.error(error);
          });
    },
    confirmBatchDelete() {
      const ids = this.selectedRows.map(row => row.id);
      axios.delete(`/pingZhan/set/batchDelete`, {data: ids})
          .then(response => {
            console.log(response);
            this.$message({
              message: '批量删除成功！',
              type: 'success'
            });
            this.initQuery();
            this.selectedRows = [];
          })
          .catch(error => {
            console.error(error);
            this.$message.error('批量删除失败');
          });
    },
    handleExport() {
      console.log('导出数据');
    },
    handleRefresh() {
      console.log('刷新缓存');
      this.initQuery()
    },
    handleSizeChange(val) {
      this.pageSize = val;
      // 重新获取数据
      this.initQuery()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // 重新获取数据
      this.initQuery()
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
      this.isRowSelected = val.length > 0;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    showAddDialog() {
      this.addDialogVisible = true;
    },
    submitAdd() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log("formattedDate")
      console.log(formattedDate)
      const formData = {
        remark: this.addForm.remark,
        day: this.addForm.day,
        createtime: formattedDate
      };
      this.axios.post('/pingZhan/set', formData)
          .then(response => {
            console.log(response);
            this.addDialogVisible = false;
            this.$message({
              message: '添加成功！',
              type: 'success'
            });
            this.addForm = {
              remark: '',
              day: '',
            }
            this.initQuery()
          })
          .catch(error => {
            console.error(error);
          });
    },
    submitEdit() {
      axios.put('/pingZhan/set', this.editForm)
          .then(response => {
            console.log(response);
            this.$message({
              message: '修改成功！',
              type: 'success'
            });
            this.editDialogVisible = false;
            this.initQuery();
          })
          .catch(error => {
            console.error(error);
          });
    }
  },
  mounted() {
    this.initQuery();
  }
};
</script>

<style scoped>
.container {
  padding-left: 20px;
}

.custom-label {
  font-size: 16px; /* 调整字体大小 */
  font-weight: bold; /* 加粗 */
}

.el-form-item {
  margin-right: 20px;
}

/* 使用 ::v-deep 选择器来深度作用于 Element UI 的表头样式 */
::v-deep.el-table th {
  background-color: #f5f7fa;
  font-weight: bold; /* 表头加粗 */
}

.button-row {
  margin-left: 0;
}

/* 分页居右对齐 */
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>